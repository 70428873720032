@import '../../../../variables.scss';

.header{
    color: $base-color;
    background-color: $base-bg-header-color;

    .MuiAvatar-colorDefault{
        color: $base-color;
        background-color: $base-bg-header-color;
    }
}

.dft-color{
    color: $base-color
}

.centerContent{
    text-align: center;
}

.just-center{
    justify-content: center;
}
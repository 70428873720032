@import '../../../../variables.scss';

.header{
    color: $base-color;
    background-color: $base-bg-header-color;

    .MuiAvatar-colorDefault{
        color: $base-color;
        background-color: $base-bg-header-color;
    }

    .avatar-size-bid{
        width:60px;
        height: 60px;
    }
}

.dft-color{
    color: $base-color
}

.centerContent{
    text-align: center;
}

.just-center{
    justify-content: center;
}

.message-box{
    width:100%;
}
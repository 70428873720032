.Container{
    padding: 0 !important;

    .appBar_root{
        flex-grow: 1;

        .title{
            flex-grow: 1;
        }
    }
}


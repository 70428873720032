@import '../variables.scss';
.loader-container{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;

    .loader{
        font-size: 7em;
        color: $base-color; 
    }
}